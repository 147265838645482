
export const menus = [
	{
		state: '/',
		name: 'home',
		type: 'link',
	},
	{
		state: '/about',
		name: 'about',
		type: 'link',
	},
	{
		state: '/directory',
		name: 'directory',
		type: 'link',
	},
	{
		state: '/news',
		name: 'news',
		type: 'link',
	},
	{
		state: '/admin/dashboard',
		name: 'members',
		type: 'link',
	},
]

// export default menus;
