<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar">
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner pt-0">
          <ul
            :data-submenu-title="$t('Components.AdminSidebar.submenuTitle0')"
            v-if="roles.includes('user')"
          >
            <li :class="{ active: $route.path === '/admin/dashboard' }">
              <router-link to="/admin/dashboard"
                ><i class="fa fa-tachometer"></i>
                {{ $t('Components.AdminSidebar.link1') }}</router-link
              >
            </li>
          </ul>
          <ul
            :data-submenu-title="$t('Components.AdminSidebar.submenuTitle1')"
            v-if="roles.includes('super_admin') || roles.includes('fiaf_admin')"
          >
            <li :class="{ active: $route.path === '/admin/users' }">
              <router-link to="/admin/users"
                ><i class="fa fa-users"></i>
                {{ $t('Components.AdminSidebar.link2') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/data' }">
              <router-link to="/admin/data"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link3') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/countries' }">
              <router-link to="/admin/countries"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link15') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/categories' }">
              <router-link to="/admin/categories"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link16') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/franchise-associations' }">
              <router-link to="/admin/franchise-associations"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link4') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/directors' }">
              <router-link to="/admin/directors"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link5') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/previous-directors' }">
              <router-link to="/admin/previous-directors"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link6') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/meetings' }">
              <router-link to="/admin/meetings"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link7') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/statement-accounts' }">
              <router-link to="/admin/statement-accounts"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link8') }}</router-link
              >
            </li>
          </ul>
          <ul
            :data-submenu-title="$t('Components.AdminSidebar.submenuTitle2')"
            v-if="roles.includes('super_admin') || roles.includes('publisher')"
          >
            <li :class="{ active: $route.path === '/admin/publications' }">
              <router-link to="/admin/publications"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link9') }}</router-link
              >
            </li>
          </ul>
          <ul
            :data-submenu-title="$t('Components.AdminSidebar.submenuTitle3')"
            v-if="roles.includes('super_admin') || roles.includes('association_admin')"
          >
            <li
              :class="{
                active: $route.path === '/admin/affiliated/my-association',
              }"
            >
              <router-link to="/admin/affiliated/my-association"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link10') }}</router-link
              >
            </li>
            <li
              :class="{
                active: $route.path === '/admin/affiliated/meetings',
              }"
            >
              <router-link to="/admin/affiliated/meetings"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link11') }}</router-link
              >
            </li>
            <li :class="{ active: $route.path === '/admin/statement-accounts' }">
              <router-link to="/admin/affiliated/statement-accounts"
                ><i class="fa fa-list"></i
                >{{ $t('Components.AdminSidebar.link12') }}</router-link
              >
            </li>
          </ul>
          <ul :data-submenu-title="$t('Components.AdminSidebar.submenuTitle4')">
            <li :class="{ active: $route.path === '/admin/profile' }">
              <router-link to="/admin/profile"
                ><i class="fa fa-user"></i>
                {{ $t('Components.AdminSidebar.link13') }}</router-link
              >
            </li>
            <li>
              <a @click.prevent="logout()"
                ><i class="fa fa-sign-out"></i>
                {{ $t('Components.AdminSidebar.link14') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a class="db-trigger" @click="toggleMenu()">
      <v-icon>mdi-arrow-left-right</v-icon>
    </a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        roles: [],
      }
    },
    created() {
      this.getRoles()
    },
    computed: {
      name() {
        if (this.$session.get('tokenSession')) {
          return this.$session.get('userName')
        } else {
          return false
        }
      },
      lastname() {
        if (this.$session.get('tokenSession')) {
          return this.$session.get('userLastname')
        } else {
          return false
        }
      },
    },
    methods: {
      toggleMenu() {
        if ($('#wrapper').hasClass('sidebar-in')) {
          $('#wrapper').removeClass('sidebar-in')
        } else {
          $('#wrapper').addClass('sidebar-in')
        }
      },
      getRoles() {
        if (this.$session.get('roles')) {
          this.$session.get('roles').forEach((role) => {
            this.roles.push(role.name)
          })
        }
      },
      logout() {
        this.$swal({
          title: `Hasta pronto ` + this.name + ' ' + this.lastname,
          html: `Regresa cuando quieras para seguir disfrutando de nuestros servicios`,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        })
        axios
          .get('auth/logout')
          .then(() => {
            this.$session.destroy()
          })
          .catch(() => {
            this.$session.destroy()
          })
          .finally(() => {
            setTimeout(() => {
              if (this.$route.path !== '/') {
                this.$router.push('/')
              } else {
                location.reload()
              }
            }, 4000)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dashboard-nav ul li a {
    color: #0c2c7c;
    padding: 5px 10px;
  }
  .app-admin-sidebar .db-trigger {
    background: #03184d !important;
    height: 30px;
    right: -30px;
    line-height: 30px;
    top: 5%;
    width: 30px;
  }
</style>
