import Vue from 'vue'
import axios from './api'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import store from "./store";
import VueSession from 'vue-session'
import VueSweetalert2 from 'vue-sweetalert2'
import VModal from 'vue-js-modal'
import Multiselect from 'vue-multiselect'
import { VueMaskDirective } from 'v-mask'
import { Datetime } from 'vue-datetime'
import Vue2Editor from "vue2-editor"
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify'
import Carousel3d from 'vue-carousel-3d'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'bootstrap';

// router
import router from './router'

// include script file
import './lib/CustomScript'

// include all css files
import './lib/CustomCss'

Vue.use(VueAwesomeSwiper)
Vue.use(Carousel3d)
Vue.use( VueSession, { persist: true } )
Vue.use(Vue2Editor)
Vue.use(VModal, {
   componentName: 'modal',
	dynamicDefault: {
      adaptive: true,
		resizable: true,
		height: 'auto',
	},
})
Vue.component('multiselect', Multiselect)

Vue.directive( 'mask', VueMaskDirective );
Vue.directive( 'datetime', Datetime );

const options = {
	confirmButtonColor: '#0c2c7c',
	cancelButtonColor: '#ff7674',
}
Vue.use(VueSweetalert2, options)


window.$ = require('jquery')
window.JQuery = require('jquery')
window.swal = VueSweetalert2

Vue.use(VueGoogleMaps, {
   load: {
      key: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk' // Add your here your google map api key
   }
})

Vue.config.productionTip = false

axios.defaults.timeout = 300000
axios.defaults.baseURL = process.env.VUE_APP_FIAF_API

// Add a response interceptor
axios.interceptors.response.use(
	response => {
		if ( response.status === 200 || response.status === 201 || response.status === 204 ) {
			return Promise.resolve( response );
		} else {
			return Promise.reject( response );
		}
	},
	error => {
		if ( error.response && error.response.status ) {
			switch ( error.response.status ) {
				case 401:
					if ( error.response.config.url == 'auth/login' ) {
						Vue.swal( {
							icon: 'error',
							title: 'Datos inválidos',
							html: 'La combinación de correo electrónico y contraseña es incorrecta',
							showCancelButton: false,
							showConfirmButton: true,
						} )
					}
					if ( localStorage.getItem( 'vue-session-key' ) && JSON.parse( localStorage.getItem( 'vue-session-key' ) ).tokenSession) {
						Vue.swal( {
							icon: 'error',
							title: `Acceso restringido`,
							html: `Tu sesión ha caducado o no tienes permisos suficientes para continuar.`,
							showCancelButton: false,
							showConfirmButton: true,
						} )
						localStorage.removeItem( 'vue-session-key' )
						router.push( '/' )
					}
					// throw new Error( error )
				break

				case 403:
						Vue.swal( {
							icon: 'error',
							title: 'Acceso restringido',
							html: 'Actualmente no tienes permisos suficientes para realizar esta acción.',
							showCancelButton: false,
							showConfirmButton: true,
						} )
				break


				case 422:
					let contentMessage = ''
					let arrayOfErrors = []
					if ( error.response.data.errors) {
						contentMessage = '<p>Por favor, verifique la información suministrada e intente nuevamente</p><dl style="text-align: justify">'
						arrayOfErrors = Object.entries(error.response.data.errors)
						arrayOfErrors.forEach(error => {
							contentMessage = contentMessage + '<dt style="text-transform: capitalize">' + error[ 0 ] + '</dt>'
							error[1].forEach(item => {
								contentMessage = contentMessage + '<dd>- ' + item + '</dd >'
							})
						})
						contentMessage = contentMessage + '</dl>'
					} else {
						contentMessage = 'La información sumistrada es inválida'
					}
					Vue.swal( {
						icon: 'error',
						title: error.response.data.message ? error.response.data.message : 'Ocurrió un error',
						html: contentMessage,
						showCancelButton: false,
						showConfirmButton: true,
					} )
					// throw new Error( error )
					break
				}
		}
		return Promise.reject( error )
	}
)

window.axios = axios

new Vue({
	i18n,
	store,
	router,
	vuetify,
	components: { App },
	beforeCreate() {
		this.$store.commit("initialiseStore");
  },
	render: h => h(App),
}).$mount('#app')
