<template>
  <footer>
    <cookie-law
      storageType="localStorage"
      theme="dark-lime"
      :buttonText="$t('Components.FrontCookiesDisclaimer.buttonText')"
    >
      <div slot="message">
        <h3 style="color: #fff">{{ $t('Components.FrontCookiesDisclaimer.title') }}</h3>
        {{ $t('Components.FrontCookiesDisclaimer.text') }}
        <router-link to="/">{{
          $t('Components.FrontCookiesDisclaimer.linkText')
        }}</router-link>
      </div>
      <!-- <div slot-scope="props">
        <button class="skew" @click="props.accept"><span>I accept</span></button>
        <p>This site uses 🍪</p>
        <button class="skew" @click="props.close"><span>Ignore me</span></button>
      </div> -->
    </cookie-law>
  </footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    components: { CookieLaw },
  }
</script>
<style lang="scss" scoped>
  a {
    color: #92acf3;
  }
</style>
