<template>
  <div class="position-relative banner-cont">
    <!-- Hero for FREE version -->
    <section class="section section-shaped py-0 d-flex">
      <!-- Background circles -->
      <div
        class="shape shape-style-desktop shape-primary"
        v-if="$vuetify.breakpoint.mdAndUp"
      ></div>
      <div
        class="shape shape-style-mobile shape-primary"
        v-if="$vuetify.breakpoint.smAndDown"
      ></div>
    </section>
  </div>
</template>

<script>
  export default {
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/custom/responsive-variables.scss';
  .section-shaped .shape-style-desktop.shape-primary {
    background-image: url('/static/images/main-banner-desktop.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #03184d;
  }
  .section-shaped .shape-style-mobile.shape-primary {
    background-image: url('/static/images/main-banner-mobile.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #03184d;
  }
  .section-shaped {
    height: calc(100vw * 0.15625);
  }
  .display-2 {
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 1.3;
    padding: 0 5%;
  }
  @media #{$tablet-device} {
    .section-shaped {
      height: calc(100vw * 0.2);
    }
  }
  @media #{$large-mobile} {
    .section-shaped {
      height: calc(100vw * 0.25);
    }
    .display-2 {
      font-size: 1.7rem;
    }
  }
  @media #{$small-mobile} {
    .section-shaped {
      height: calc(100vw * 0.4);
    }
    .display-2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
  @media #{$extra-small-mobile} {
    .section-shaped {
      height: calc(100vw * 0.5);
    }
    .display-2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
</style>
